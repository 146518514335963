import React from 'react';
import styled, { css } from 'styled-components';
import Layout from '../components/Layout';
import MenuWedding from '../components/MenuWedding';
import QaWedding from '../components/QaWedding';
import SEO from '../components/SEO';
import FooterButton from '../components/FooterButton';
import VoiceBanner from '../components/VoiceBanner';

type BasePagePropsType = {
  className?: string;
};

const BasePage: React.FC<BasePagePropsType> = ({ className }) => {
  return (
    <div className={className}>
      <Layout>
        <SEO title={'フォト婚'} path={'wedding'} />
        <MenuWedding />
        <VoiceBanner category={'wedding'} />
        <QaWedding />
        <FooterButton category={'wedding'} />
      </Layout>
    </div>
  );
};

const Page = styled(BasePage)`
  ${() => {
    return css`
      & {
        .spacer30 {
          margin-bottom: 30px;
        }
      }
    `;
  }}
`;
export default Page;
