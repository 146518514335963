import React from 'react';
import styled, { css } from 'styled-components';

type BaseMenuWeddingPropsType = {
  className?: string;
};

const BaseMenuWedding: React.FC<BaseMenuWeddingPropsType> = ({ className }) => (
  <div className={className}>
    <div className="row">
      <div className="col-12">
        <div className="spacer30"></div>

        <div>
          <p className="title">フォト婚</p>
          <p className="sub-title">photo wedding</p>
          <p className="caption">結婚式は挙げないけど写真で残すフォト婚</p>
          <p className="plan-title border-color-pink">洋装プラン</p>
          <p className="plan-sub-title">ドレスだけ着たい方におすすめのプランです</p>
          <p className="plan-price">
            基本プラン <span>88,000円</span>
          </p>
          <ul className="plan-contents">
            プランに含まれる内容
            <li>ドレス</li>
            <li>タキシード</li>
            <li>衣装小物</li>
            <li>ヘアセット</li>
            <li>メイク</li>
            <li>撮影料</li>
            <li>台紙アルバム1カット1冊</li>
          </ul>

          <p className="plan-title border-color-green">和装・洋装プラン</p>
          <p className="plan-sub-title">和装も着たい方におすすめのプランです</p>
          <p className="plan-price">
            基本プラン <span>132,000円</span>
          </p>
          <ul className="plan-contents">
            プランに含まれる内容
            <li>ドレス</li>
            <li>タキシード</li>
            <li>打掛</li>
            <li>羽織袴</li>
            <li>衣装小物</li>
            <li>ヘアセット</li>
            <li>メイク</li>
            <li>撮影料</li>
            <li>台紙アルバム2カット1冊</li>
          </ul>

          <p className="plan-title border-color-orange">当日ご用意いただくもの</p>
          <p className="plan-sub-title">以下のものをご準備ください</p>
          <ul className="ready-contents">
            <li className="list-title">女性</li>
            <li>洋装&nbsp;&nbsp;&nbsp;ひざ下ストッキング</li>
            <li>和装&nbsp;&nbsp;&nbsp;足袋・肌襦袢</li>
            <li className="list-title">男性</li>
            <li>洋装&nbsp;&nbsp;&nbsp;黒靴下</li>
            <li>和装&nbsp;&nbsp;&nbsp;足袋・U首の肌着</li>
          </ul>

          <p className="plan-title border-color-orange">衣装オプション料金</p>
          <p className="price-sub-title">
            新婦様のお選びいただく衣装によってランクアップ衣装をご用意しています
          </p>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>ランクアップ衣装料金</td>
                <td>22,000円</td>
              </tr>
            </tbody>
          </table>

          <p className="plan-title border-color-orange">写真オプション料金</p>
          <p className="price-sub-title">上記基本プランに台紙アルバムを追加することができます</p>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>1カット追加</td>
                <td>9,900円</td>
              </tr>
              <tr>
                <td>2カット追加</td>
                <td>19,800円</td>
              </tr>
              <tr>
                <td>3カット追加</td>
                <td>29,700円</td>
              </tr>
              <tr>
                <td>4カット追加</td>
                <td>39,600円</td>
              </tr>
            </tbody>
          </table>

          <p className="price-sub-title">ご家族様用の台紙</p>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>1カット</td>
                <td>9,900円</td>
              </tr>
              <tr>
                <td>2カット</td>
                <td>19,800円</td>
              </tr>
              <tr>
                <td>3カット</td>
                <td>29,700円</td>
              </tr>
              <tr>
                <td>4カット</td>
                <td>39,600円</td>
              </tr>
            </tbody>
          </table>

          <p className="price-sub-title">写真額</p>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>6切額1枚</td>
                <td>9,900円</td>
              </tr>
            </tbody>
          </table>

          <p className="price-sub-title">写真集アルバム</p>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>A4サイズ4〜8カット程度1冊</td>
                <td>44,000円</td>
              </tr>
            </tbody>
          </table>

          <p className="price-sub-title">写真データ</p>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>1カット</td>
                <td>2,200円</td>
              </tr>
              <tr>
                <td>全カット</td>
                <td>27,500円</td>
              </tr>
            </tbody>
          </table>

          <p className="plan-title border-color-orange">その他の料金</p>

          <p className="price-sub-title">ご家族様での撮影</p>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>撮影料金</td>
                <td>3,300円</td>
              </tr>
            </tbody>
          </table>

          <p className="price-sub-title">出張撮影</p>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>出張撮影料金</td>
                <td>22,000円</td>
              </tr>
            </tbody>
          </table>

          <p className="price-sub-title">
            撮影衣装の数、撮影の時間帯などに応じて撮影準備費、移動費、入館料等別途いただく場合があります
          </p>
        </div>

        <div className="spacer30"></div>

        <h4 className="caption-title">オプション料金</h4>

        <div className="spacer30"></div>

        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>お母様の着付料</td>
              <td>8,800円</td>
            </tr>
            <tr>
              <td>お母様のヘアセット</td>
              <td>4,400円</td>
            </tr>
            <tr>
              <td>お母様のメイク</td>
              <td>3,300円</td>
            </tr>
            <tr>
              <td>お母様着物レンタル</td>
              <td>11,000円</td>
            </tr>
          </tbody>
        </table>
        <div className="spacer30"></div>
      </div>
    </div>
  </div>
);

const MenuWedding = styled(BaseMenuWedding)`
  ${() => {
    return css`
      & {
        p.title {
          font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0;
          text-align: left;
          height: 28px;
          left: 44px;
          top: 69px;
          margin: 0;
        }
        p.sub-title {
          left: 44px;
          font-family: Roboto;
          font-size: 13px;
          font-style: normal;
          font-weight: 200;
          line-height: 15px;
          letter-spacing: 0;
          text-align: left;
          margin-left: 4px;
        }
        p.caption {
          margin-top: 44px;
          margin-bottom: 20px;
          left: 48px;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 200;
          line-height: 14px;
          letter-spacing: 0;
          text-align: left;
        }
        p.plan-title {
          margin-top: 50px;
          left: 50px;
          padding-left: 12px;
          font-family: Noto Serif JP;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 0;
          text-align: left;
        }
        .border-color-pink {
          border-left: solid 5px #ffb7b7;
        }
        .border-color-green {
          border-left: solid 5px #89d3cb;
        }
        .border-color-orange {
          border-left: solid 5px #cca04c;
        }
        p.plan-price {
          margin-top: 23px;
          margin-right: 20px;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 200;
          line-height: 14px;
          letter-spacing: 0;
          text-align: right;
          color: #000000;
          span {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 16px;
            color: #d39393;
          }
        }
        ul.plan-contents {
          left: 60px;
          margin: 30px;
          padding: 30px;
          border: solid 1px #cacaca;
          border-radius: 3px;
          font-family: Roboto;
          font-size: 13px;
          font-style: normal;
          font-weight: 200;
          line-height: 14px;
          letter-spacing: 0;
          text-align: center;
          list-style: none;
          li {
            margin-top: 20px;
          }
        }
        ul.ready-contents {
          left: 60px;
          padding: 20px;
          font-family: Roboto;
          font-size: 15px;
          font-style: normal;
          font-weight: 200;
          line-height: 14px;
          letter-spacing: 0;
          text-align: left;
          list-style: none;
          li.list-title {
            margin-left: -40px;
          }
          li {
            margin-top: 20px;
            padding-left: 40px;
          }
          li:first-child {
            margin-top: 0px;
          }
        }
        p.price-sub-title {
          margin-top: 20px;
          left: 48px;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0;
          text-align: left;
        }

        .spacer10 {
          margin-bottom: 10px;
        }

        .spacer20 {
          margin-bottom: 20px;
        }

        .spacer30 {
          margin-bottom: 30px;
        }

        .spacer50 {
          margin-bottom: 50px;
        }

        .spacer60 {
          margin-bottom: 60px;
        }

        .spacer100 {
          margin-bottom: 100px;
        }

        .flex-wrapper {
          display: flex;
          flex-direction: column;
        }

        .flex-wrapper-row {
          display: flex;
          flex-direction: row;
        }

        .flex-item {
          padding: 0;
          line-height: 2.5;
        }

        .w33 {
          width: 33%;
        }

        .w100 {
          width: 100%;
        }

        .pw10 {
          padding: 0 10px 0;
        }

        .flex-spacer {
          flex: 1;
          padding: 0 10px;
        }

        .plan-wrap {
          margin: 0 0 10px;
          padding: 10px;
          border: solid 1px #eee;
          border-radius: 10px;
        }

        .price-memo {
          font-weight: 700;
        }

        .plan-price {
          font-size: 1.4rem;
          font-weight: 700;
          color: #dc3545;
          letter-spacing: -0.05rem;
        }

        .option-title {
          font-size: 1.5rem;
          font-weight: 500;
        }

        .form-title {
          font-size: 1.2rem;
          font-weight: 500;
          margin-bottom: 1rem;
        }

        table thead th {
          text-align: center;
          width: 50%;
        }

        table tbody tr th,
        td {
          text-align: center;
          width: 50%;
        }
        ul {
          margin: 0;
        }
        li {
          margin: 0;
        }
        li.title {
          list-style-type: none;
          margin-left: -26px;
        }
        .caption-title {
          font-weight: 700;
          text-align: center;
          margin-bottom: 30px;
        }

        .carousel-wrapper {
          margin: 30px;
        }
        .total-price-wrapper {
          margin-top: 30px;
          background: #f3b54a;
          padding: 10px 30px;
          border-radius: 6px;
        }
        .total-price {
          font-weight: 700;
          font-size: 1.5rem;
          color: white;
        }
        .kanban {
          display: flex;
          flex-direction: column;
          border: solid 3px #ff497d;
          margin: 20px;
          padding: 30px;
          border-radius: 6px;
          p.title {
            font-size: 20px;
            font-weight: 700;
            text-align: center;
          }
          p.sub-title {
            font-size: 20px;
            font-weight: 700;
            text-align: center;
          }
          p.limited-price {
            font-size: 30px;
            font-weight: 700;
            text-align: center;
            margin: 24px;
            color: #ff497d;
          }
          p.price {
            font-size: 20px;
            text-align: center;
            margin: 10px;
          }
          p.description {
            font-size: 24px;
            font-weight: 700;
            text-align: center;
          }
        }

        h2 {
          margin: 30px 0;
          padding: 1.65rem 2rem;
          background-color: rgba(165, 210, 255, 0.4);
          background-image: linear-gradient(90deg, rgba(165, 210, 255, 0.3) 50%, transparent 50%),
            linear-gradient(rgba(165, 210, 255, 0.3) 50%, transparent 50%);
          background-size: 40px 40px;
          border-radius: 6px;
        }
        @media screen and (max-width: 480px) {
          .flex-item {
            margin-bottom: 10px;
          }
          .flex-wrapper-row {
            flex-direction: column;
          }
          .total-price {
            font-size: 1.2rem;
          }
          .kanban {
            margin: 20px 0px;
            padding: 10px;
            p.title {
              margin-top: 20px;
              font-size: 1.2rem;
            }
            p.sub-title {
              font-size: 1.2rem;
            }
            p.limited-price {
              font-size: 1.4rem;
              margin: 10px;
            }
            p.price {
              font-size: 1rem;
            }
            p.description {
              font-size: 1rem;
            }
          }
        }
      }
    `;
  }}
`;

export default React.memo(MenuWedding);
